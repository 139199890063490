<template>
  <th-wrapper
    :title="$t('pages.settings.pos.tipping.form.title')"
    :data-testid="inited ? 'inited' : undefined"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :lg="8" :md="12">
          <!-- Enabled -->
          <el-form-item prop="enabled">
            <el-switch
              id="enabled"
              v-model="enabled"
              :active-text="
                $t('pages.settings.pos.tipping.form.properties.enabled.label')
              "
              data-testid="enabled"
            />
          </el-form-item>

          <!-- Max factor -->
          <el-form-item
            prop="max_factor"
            :label="
              $t('pages.settings.pos.tipping.form.properties.max_factor.label')
            "
          >
            <th-number-input
              id="max_factor"
              v-model="max_factor"
              class="w-1/2"
              :upper-limit="1.0"
              :lower-limit="0"
              percent
              :locale="$i18n.locale"
              data-testid="max_factor"
            />
          </el-form-item>

          <!-- Payment types -->
          <el-form-item
            prop="payment_types"
            :label="
              $t(
                'pages.settings.pos.tipping.form.properties.payment_types.label'
              )
            "
          >
            <el-select
              id="payment_types"
              v-model="payment_types"
              class="w-full"
              multiple
              :popper-append-to-body="false"
              data-testid="payment_types"
            >
              <el-option
                v-for="option in paymentOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>

          <hr class="mt-5 mb-5" />

          <!-- Owner tax account -->
          <el-form-item prop="owner_item_tax">
            <th-input-title
              :title="
                $t(
                  'pages.settings.pos.tipping.form.properties.owner_item_tax.label'
                )
              "
              :info="
                $t(
                  'pages.settings.pos.tipping.form.properties.owner_item_tax.info'
                )
              "
              :required="enabled"
            />
            <el-select
              id="owner_item_tax"
              v-model="owner_item_tax"
              class="w-full"
              :popper-append-to-body="false"
              data-testid="owner_item_tax"
            >
              <el-option
                v-for="option in taxes"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>

          <!-- Owner revenue account -->
          <el-form-item
            prop="owner_item_account"
            :label="
              $t(
                'pages.settings.pos.tipping.form.properties.owner_item_account.label'
              )
            "
          >
            <el-select
              id="owner_item_account"
              v-model="owner_item_account"
              class="w-full"
              :popper-append-to-body="false"
              data-testid="owner_item_account"
            >
              <el-option
                v-for="option in accounts"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>

          <hr class="mt-5 mb-5" />

          <!-- Employee tax account -->
          <el-form-item prop="employee_item_tax" :required="enabled">
            <th-input-title
              :title="
                $t(
                  'pages.settings.pos.tipping.form.properties.employee_item_tax.label'
                )
              "
              :info="
                $t(
                  'pages.settings.pos.tipping.form.properties.employee_item_tax.info'
                )
              "
              :required="enabled"
            />
            <el-select
              id="employee_item_tax"
              v-model="employee_item_tax"
              class="w-full"
              :popper-append-to-body="false"
              data-testid="employee_item_tax"
            >
              <el-option
                v-for="option in taxes"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>

          <!-- Employee revenue account -->
          <el-form-item
            prop="employee_item_account"
            :label="
              $t(
                'pages.settings.pos.tipping.form.properties.employee_item_account.label'
              )
            "
          >
            <el-select
              id="employee_item_account"
              v-model="employee_item_account"
              class="w-full"
              :popper-append-to-body="false"
              data-testid="employee_item_account"
            >
              <el-option
                v-for="option in accounts"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { getTypesLabels } from '@/views/accounting/payment-options/helpers'

export default {
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      paymentTypesLabels: getTypesLabels(this),
      paymentOptions: [],
      taxes: [],
      accounts: [],
      inited: false
    }
  },

  computed: {
    form() {
      return {
        enabled: this.enabled,
        max_factor: this.max_factor,
        payment_types: this.payment_types,
        owner_item_tax: this.owner_item_tax,
        owner_item_account: this.owner_item_account,
        employee_item_tax: this.employee_item_tax,
        employee_item_account: this.employee_item_account
      }
    },

    rules() {
      return {
        payment_types: [
          {
            required: this.enabled,
            type: 'array',
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        owner_item_account: [
          {
            required: this.enabled,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        owner_item_tax: [
          {
            required: this.enabled,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        employee_item_account: [
          {
            required: this.enabled,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        employee_item_tax: [
          {
            required: this.enabled,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    },

    enabled: {
      get() {
        return this.modelValue.enabled || false
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          enabled: value
        })
      }
    },

    max_factor: {
      get() {
        const value = this.modelValue.max_factor ?? 1.3
        return value - 1
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          max_factor: value + 1
        })
      }
    },

    payment_types: {
      get() {
        return this.modelValue.payment_types || []
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          payment_types: value
        })
      }
    },

    owner_item_tax: {
      get() {
        return safeGet(this.modelValue, 'owner_item.tax') || null
      },
      set(value) {
        const item = {
          ...this.getEmptyProductItem(),
          ...(this.modelValue.owner_item || {})
        }
        item.tax = value
        this.$emit('update:modelValue', {
          ...this.modelValue,
          owner_item: item
        })
      }
    },

    owner_item_account: {
      get() {
        return safeGet(this.modelValue, 'owner_item.account') || null
      },
      set(value) {
        const item = {
          ...this.getEmptyProductItem(),
          ...(this.modelValue.owner_item || {})
        }
        item.account = value
        this.$emit('update:modelValue', {
          ...this.modelValue,
          owner_item: item
        })
      }
    },

    employee_item_tax: {
      get() {
        return safeGet(this.modelValue, 'employee_item.tax') || null
      },
      set(value) {
        const item = {
          ...this.getEmptyProductItem(),
          ...(this.modelValue.employee_item || {})
        }
        item.tax = value
        this.$emit('update:modelValue', {
          ...this.modelValue,
          employee_item: item
        })
      }
    },

    employee_item_account: {
      get() {
        return safeGet(this.modelValue, 'employee_item.account') || null
      },
      set(value) {
        const item = {
          ...this.getEmptyProductItem(),
          ...(this.modelValue.employee_item || {})
        }
        item.account = value
        this.$emit('update:modelValue', {
          ...this.modelValue,
          employee_item: item
        })
      }
    }
  },

  async mounted() {
    await this.fetchResources()
    this.inited = true
  },

  methods: {
    async validate(resolve) {
      this.$refs.form.validate(resolve)
    },

    async fetchResources() {
      const {
        paymentOptions = [],
        taxes = [],
        accounts = []
      } = await this.$resourceFetch(
        {
          resource: 'paymentOptions',
          errorHandler: (error) =>
            this.$handleResourceFetchError(
              error,
              this.$t('common.resource.payment_option.plural')
            ),
          fallback: () => ({ data: [] })
        },
        {
          resource: 'taxes',
          errorHandler: (error) =>
            this.$handleResourceFetchError(
              error,
              this.$t('common.resource.tax.plural')
            ),
          fallback: () => ({ data: [] })
        },
        {
          resource: 'accounts',
          handler: () =>
            th.accounts().getAll({
              query: { type: 'revenue', deleted: false, active: true }
            }),
          errorHandler: (error) =>
            this.$handleResourceFetchError(
              error,
              this.$t('common.resource.revenue_account.plural')
            ),
          fallback: () => ({ data: [] })
        }
      )
      this.paymentOptions = paymentOptions
        .filter((item) => {
          return ['cash', 'card', 'card_opi'].includes(item.type)
        })
        .map((item) => {
          const value = item.type
          return {
            value,
            label: this.paymentTypesLabels[value] || value
          }
        })
      this.taxes = taxes.map((item) => {
        const value = item.id
        return {
          value,
          label: `${item.fa_account_number || ''} - ${item.name}`.trim()
        }
      })
      this.accounts = accounts.map((item) => {
        const value = item.id
        return {
          value,
          label: `${item.fa_account_number || ''} - ${item.name}`.trim()
        }
      })
    },

    getEmptyProductItem() {
      return {
        tax: null,
        account: null,
        product: null,
        product_name: null,
        product_group: null,
        product_number: null,
        product_group_name: null,
        product_group_number: null
      }
    }
  }
}
</script>
