<template>
  <section>
    <tipping-form ref="tippingForm" v-model="form" />
  </section>
</template>

<script>
import get from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import TippingForm from './components/tipping-form.vue'
import cloneDeep from 'clone-deep'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.settings.tipping.title')
    }
  },
  components: {
    TippingForm
  },
  computed: {
    form: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'tips',
          {}
        )
      },
      set: function (value) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tips',
          value
        })
      }
    }
  },
  methods: {
    async validate(resolve) {
      this.$refs.tippingForm.validate(resolve)
    },
    async handleSave() {
      try {
        this.form = await this.syncTipsConfiguration()
        await this.$store.dispatch('Config/saveClientAccountConfiguration')
      } catch (error) {
        return [error]
      }
    },

    async syncTipsConfiguration() {
      const result = cloneDeep(this.form)
      if (!result.owner_item && !result.employee_item) {
        return result
      }

      const searchResponse = await th.products().getAll({
        query: {
          type: ['tip_owner', 'tip_employee'],
          deleted: false
        }
      })

      const tipOwnerItem = searchResponse.data.find(
        (item) => item.type === 'tip_owner'
      )
      result.owner_item = await this.syncTipsConfigurationItem(
        result.owner_item,
        tipOwnerItem,
        {
          name: 'Tip (Owner)',
          type: 'tip_owner'
        }
      )

      const tipEmployeeItem = searchResponse.data.find(
        (item) => item.type === 'tip_employee'
      )
      result.employee_item = await this.syncTipsConfigurationItem(
        result.employee_item,
        tipEmployeeItem,
        {
          name: 'Tip (Employee)',
          type: 'tip_employee'
        }
      )
      return result
    },

    async syncTipsConfigurationItem(item, product, { name, type }) {
      if (!item) {
        return item
      }
      if (!item.account) {
        throw new Error('item doesn\'t have "account"')
      }

      const syncedFields = product
        ? await this.updateTipsConfigurationProduct(product.id, {
            account: item.account,
            tax: item.tax
          })
        : await this.createTipsConfigurationProduct({
            name,
            type,
            account: item.account,
            tax: item.tax
          })

      const result = {
        ...item,
        ...syncedFields
      }

      if (!result.product) {
        throw new Error('item still doesn\'t have "product"')
      }
      if (!result.product_name) {
        throw new Error('item still doesn\'t have "product_name"')
      }
      if (!result.account) {
        throw new Error('item still doesn\'t have "account"')
      }
      // At some point we will cancel "required" for tax, but for now it must be there
      if (!result.tax) {
        throw new Error('item still doesn\'t have "tax"')
      }
      return result
    },

    async createTipsConfigurationProduct({ name, type, account, tax }) {
      const { data } = await th.products().create({
        sellable: false,
        stockable: false,
        name,
        type,
        account,
        tax
      })
      return {
        product: data.id,
        product_name: data.name,
        account: data.account,
        tax: data.tax
      }
    },

    async updateTipsConfigurationProduct(id, { account, tax }) {
      const { data } = await th.products().put(id, {
        account,
        tax
      })
      return {
        product: data.id,
        product_name: data.name,
        account: data.account,
        tax: data.tax
      }
    }
  }
}
</script>
